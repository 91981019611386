import { template as template_5366f540ad974063b482b6b6ce05ceb2 } from "@ember/template-compiler";
const FKLabel = template_5366f540ad974063b482b6b6ce05ceb2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
