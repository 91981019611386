import { template as template_7a1ce2bbc3c849938de561f0e9de68e4 } from "@ember/template-compiler";
const WelcomeHeader = template_7a1ce2bbc3c849938de561f0e9de68e4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
